
export default {
  layout: 'blank',
  auth: false,
  middleware: ['isUser'],
  head () {
    return {
      titleTemplate: this.$t('title.home'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.home')
        }
      ],
    }
  },
  data () {
    return {
      appGraphic: {
        src: '',
        width: 0,
        height: 0
      }
    }
  },
  methods: {
    addRegisterUrl (text) {
      return text.replace('{register_url}', this.localePath(('/register')))
    }
  },
  mounted () {
    if (window.innerWidth > 757) {
      this.appGraphic = {
        src: this.$t('images.hero'),
        width: 1000,
        height: 617
      }
    } else {
      this.appGraphic = {
        src: this.$t('images.mobile_jaty'),
        width: 388,
        height: 514
      }
    }
  },

}
