
export default {
  data () {
    return {
      free_month: false,
      link: '/api/login/facebook'
    }
  },
  mounted () {
    if (this.getCookie('free-month')) {
      this.free_month = 1
    }

    this.$nextTick(() => {
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (this.free_month) {
            return this.link = process.env.BACKEND_URL + `/api/login/facebook?free_month=1&utm_source=${this.getCookie('first_utm_source')}&first_page_visited=${this.getCookie('first_page_visited')}`
          } else {
            return this.link = process.env.BACKEND_URL + `/api/login/facebook?utm_source=${this.getCookie('first_utm_source')}&first_page_visited=${this.getCookie('first_page_visited')}`
          }
        })
      })
    })
  },
  computed: {
    source () {
      return this.$store.state.source.source
    },
    firstPageVisited () {
      return this.$store.state.source.firstPageVisited
    },

  },
}
